@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-regular.woff2")
      format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-100.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-100.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-100italic.woff2")
      format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-100italic.woff")
      format("woff");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-200.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-200.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-200italic.woff2")
      format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-200italic.woff")
      format("woff");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-300.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-300italic.woff2")
      format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-300italic.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-500.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-500.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-600.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-600.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-800.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-800.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/montserrat-v23-latin-900.woff2") format("woff2"),
    url("../fonts/montserrat/montserrat-v23-latin-900.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
